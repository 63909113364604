@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-20%);
    }
  }
  
  .animate-slide {
    animation: slide 13s linear infinite;
  }

  @keyframes gradient-x {
    0%, 100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }

  .animate-gradient-x {
    animation: gradient-x 15s ease infinite;
    background-size: 400% 400%;
  }

  /* Add these font family definitions */
  .font-montserrat {
    font-family: 'Montserrat', sans-serif;
  }

  .font-inter {
    font-family: 'Inter', sans-serif;
  }

  .font-roboto {
    font-family: 'Roboto', sans-serif;
  }

  /* Add these heading-specific styles */
  .font-montserrat {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: -0.025em;  /* Tighter letter spacing for headings */
  }

  /* Add a specific class for main headings */
  .heading-primary {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: -0.025em;
    font-weight: 700;
    line-height: 1.2;
    color: #111827;  /* text-gray-900 */
  }

  /* Add more whitespace around sections */
  section {
    margin-bottom: 6rem;
  }

  /* Enhance heading hierarchy */
  h2 {
    margin-bottom: 4rem;
  }

  h3 {
    margin-bottom: 1.5rem;
  }

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600&display=swap');
@import url('https://api.fontshare.com/v2/css?f[]=clash-display@400,500,600,700&display=swap');

/* Date Picker Custom Styles */
.react-datepicker {
  background-color: #1A1A1A !important;
  border: 1px solid #2D2D2D !important;
  font-family: 'Syne', sans-serif !important;
}

.react-datepicker__header {
  background-color: #1A1A1A !important;
  border-bottom: 1px solid #2D2D2D !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  color: white !important;
}

.react-datepicker__day:hover {
  background-color: #3B82F6 !important;
}

.react-datepicker__day--selected {
  background-color: #3B82F6 !important;
}

.react-datepicker__day--disabled {
  color: #4B5563 !important;
}

.react-datepicker__navigation-icon::before {
  border-color: white !important;
}

.react-datepicker__navigation:hover *::before {
  border-color: #3B82F6 !important;
}